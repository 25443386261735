import React from 'react';
import { Link } from "react-router-dom";
import { JustEat_Var, Ubereat_Var } from '../../../services/Data';
function MainPageDesk() {
  return (
    <>
      <div class=" bg-mainpagemobilebg-bg lg:bg-mainpagedeskbg-bg bg-cover py-16 bg-no-repeat bg-center py-14 text-gray-400 dark:bg-gray-800 flex 
       relative z-20 items-center overflow-hidden
       md:pt-16 pt-24    
       ">
        <div class="container pt-16 text-left  px-6  relative sm:py-16 ">
          <div class=" pt-24  ">

            <h1 class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-6xl text-4xl xs:text-red-400
              sm:mb-16 mb-5 flex flex-col leading-none
                text-white  
                md:pl-16 pl-8
                font-Bebas
                shadow-neutral-300
              ">
              At woffle we believe
              <span class="text-md ">
                in fresh food
              </span>
            </h1>
            <div className='md:pl-16 pl-8 '>
              <a href={"/aboutus"} className="text-white no-underline">
                <button type="button" class="btn btn-outline-light  -mt-12 px-4 py-2 ">

                  Learn More

                  <i class="bi bi-arrow-right  ml-2 "></i>


                </button>
              </a>
            </div>


            {/* <div class="flex justify-around homePageButtonsDiv  mt-28 md:px-40">
              <a
                href={JustEat_Var}
                target="_blank"
                className="text-white mt-1" rel="noreferrer"
              >
                <button type="button" class="btn btn-light -mt-12 px-4 homePageButtonsGroup " style={{ fontWeight: 'bold' }}>

                  JUSTEAT

                </button>
              </a>
              <a
                href={Ubereat_Var}
                target="_blank"
                className="text-white mt-1" rel="noreferrer"
              >
                <button type="button" class="btn btn-light -mt-12 px-4 homePageButtonsGroup" style={{ fontWeight: 'bold' }}> UBEREATS </button>
              </a>

              <a
                href={'/order'}
                className="text-white mt-1" rel="noreferrer"
              >
                <button type="button" class="btn btn-light -mt-12 px-4 homePageButtonsGroup " style={{ fontWeight: 'bold' }}>  COLLECTION </button>
              </a>
            </div> */}


            {/* <div class="flex sm:mt-16 pl-5 mx-auto">
                <p  class="uppercase py-2 px-4    uppercase text-gray-900 rounded-lg  border-2 hover:text-yellow-400  border-white bg-white  text-md mr-4 ">
                   JUSTEAT
                   
                </p>
                <p  class="uppercase py-2 px-4  uppercase text-gray-900 rounded-lg  border-2 hover:text-yellow-400  border-white bg-white text-md mr-4 ">
                    UBEREAT
                </p>
                <p  class="uppercase py-2 px-4  uppercase text-gray-900 rounded-lg  border-2 hover:text-yellow-400 border-white bg-white  text-md mr-4 ">
                    COLLECTION
                </p>
            </div> */}
          </div>
          {/* <div class="hidden sm:block sm:w-1/3 lg:w-3/5 relative">
        </div> */}
        </div>
      </div>
    </>
  )
}

export default MainPageDesk
import React from "react";
import { Link } from "react-router-dom";

function MainPageBurger() {
  return (
    <>
    {/* lg:bg-mainpageburger-bg //TODO */}
      <div class=" bg-mainpagemobileburger-bg bg-cover bg-no-repeat bg-center text-gray-400 dark:bg-gray-800 flex relative z-20 items-center overflow-hidden h-[45rem]">
        <div class="container text-left  px-6  relative ">
          <div class=" mb-[50%] lg:mb-8  ">
            <h1
              class="font-bebas-neue 
            font-bold  uppercase 
             sm:text-4xl text-4xl xs:text-red-400
              sm:mb-16 mb-5 flex flex-col leading-none
               text-[#FFDE59]
                md:pl-16 pl-8
                font-Bebas
                shadow-neutral-300
              "
              
            >
              Through the use of freshly sourced,the highest quality ingredients and attention towards every burger and dessert, we guarantee you will soon be part of the woffle family!
           
            </h1>
            <a href={"/order"} className="text-white no-underline">
               <div className='md:pl-16 pl-8'>
            <button type="button" class="btn btn-warning  px-4 py-2 ">Menu
            <i class="bi bi-arrow-right  ml-2 "></i>
            </button>
            </div>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainPageBurger;


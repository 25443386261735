import React, { useState } from "react";
import Model from "../../common-components/model/Model";

export default function OrderRowItem({ name, items }) {
  const [model, setModel] = useState(false);
  const [modelItem, setModelItem] = useState(items[0]);

  return (
    <>
      <section class="text-gray-600 body-font">
        <div class="container px-1 pb-2 mx-auto">
          <div class="flex flex-col text-center  w-full mb-10">
            <div className="relative flex items-center">
              <div className=" ml-10 flex-grow  border-2 border-dark "></div>
              <span className="flex-shrink text-[#171E2A] font-extrabold uppercase bg-[#FFDF58] my-2 px-4 py-2 lg:text-sm sm:text-xs">
                {name}
              </span>
              <div className=" mr-10 flex-grow  border-2 border-dark "></div>
            </div>
          </div>

          {/* <div class="flex flex-wrap -m-4">
            {items.map((product) => {
              // return(<>
              //  <div class="sm:py-4 py-3 lg:w-1/2  orderRowItemClass " onClick={()=>{setModelItem(product); setModel(true)}}>
              //   <div class="h-full flex flex-row flex-col items-center sm:justify-center justify-center  sm:text-left rounded-lg bg-gray-100 mx-4 sm:pr-4">
              //     <img alt="team"  class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 orderPageItemImage" src={`/images/gallery/${product.image}`} />
              //     <div class="flex-grow text-left md:pl-4 pl-3 pr-2">
              //     <h3 className=" font-bold text-gray-900 lg:text-lg text-sm sm:text-base text-gray-700 orderPageItemTitle uppercase">{product.title}</h3>
              //         <p className="mt-2  text-gray-900 text-xs md:px-0 md:text-sm  orderPageItemDescription text-xs text-gray-700 ">{product.description}</p>
              //         <p className="-mt-2  text-red-600 xl:text-sm   md:text-sm orderPageItemPrice text-gray-400 uppercase">£{product.price}</p>
              //     </div>
              //   </div>
              // </div>
              // </>)
         
            })}
          </div> */}
          <div class="flex flex-wrap -m-4">
            {items.map((product) => {
              return (
                <>
                  <div
                    class="sm:py-4  py-3 lg:w-1/2  orderRowItemClass "
                    onClick={() => {
                      setModelItem(product);
                      setModel(true);
                    }}
                  >
                    <div class=" h-full	 flex  mx-4 sm:pr-4 justify-center  sm:text-left ">
                      <div class=" w-50 text-left bg-gray-100 pt-2  pl-3 pr-2 rounded-l-lg">
                        <h3 className=" font-bold text-gray-900 lg:text-lg text-sm sm:text-base text-gray-700 orderPageItemTitle uppercase">
                          {product.title}
                        </h3>
                        <p className={product.description == " " ? "hidden":"mt-2  text-gray-900 text-xs md:px-0 md:text-sm  orderPageItemDescription text-xs text-gray-700 "}>
                          {product.description}
                        </p>
                        <p className={product.price == " " ? "hidden":"-mt-2  text-red-600 xl:text-sm   md:text-sm orderPageItemPrice text-gray-400 "}>
                          {product.price}

                          {
            product?.largePrice?(<> regular</>):(<></>)}
                        </p>
                        {
            product?.largePrice?(<>
              <p className="-mt-2  text-red-600 xl:text-sm   md:text-sm orderPageItemPrice text-gray-400 ">£{product.largePrice} large</p>
            </>):(<></>)
           }
                     
                      </div>
                      <div
                        class="picture h-full rounded-r-lg h-150	"
                        style={{
                          backgroundImage: `url(/images/gallery/${product.image})`,

                          minHeight:'150px'
                        }}
                      ></div>
                    </div>

                    {/* <div class="details">
                <h3 className=" font-bold text-gray-900 lg:text-lg text-sm sm:text-base text-gray-700 orderPageItemTitle uppercase">{product.title}</h3>
                      <p className="mt-2  text-gray-900 text-xs md:px-0 md:text-sm  orderPageItemDescription text-xs text-gray-700 ">{product.description}</p>
                      <p className="-mt-2  text-red-600 xl:text-sm   md:text-sm orderPageItemPrice text-gray-400 uppercase">£{product.price}</p>
                </div>
                <div
                  class="picture h-full "
                  style={{backgroundImage:`url(/images/gallery/${"burgers.jpg"})`}} >

                  </div> */}

                    {/* <img alt="team"  class="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 orderPageItemImage" src={`/images/gallery/${product.image}`} />
                  <div class="flex-grow text-left md:pl-4 pl-3 pr-2">
                  <h3 className=" font-bold text-gray-900 lg:text-lg text-sm sm:text-base text-gray-700 orderPageItemTitle uppercase">{product.title}</h3>
                      <p className="mt-2  text-gray-900 text-xs md:px-0 md:text-sm  orderPageItemDescription text-xs text-gray-700 ">{product.description}</p>
                      <p className="-mt-2  text-red-600 xl:text-sm   md:text-sm orderPageItemPrice text-gray-400 uppercase">£{product.price}</p>
                  </div> */}
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>
      {/* 
<Model
         model={model} setModel={setModel} 
         modelItem={modelItem}
         extraToppings={modelItem.extraToppings || []} 
        specialRequest={modelItem.specialRequest || []} 
        price={modelItem.price || 0}
        /> */}
    </>
  );
}
